var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postPage"},[_c('div',{staticClass:"new-page-wrapper"},[_c('div',{staticClass:"postPage__line-top"},[_c('img',{staticClass:"postPage__line-top--back",attrs:{"src":require("@/assets/icons/arrow-left.svg")},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('h2',[_vm._v("КАК ПРИГОТОВИТЬ?")]),_c('div')]),_c('div',{staticClass:"postPage__line-middle"},[(_vm.groups !== null)?_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu__wrapper"},_vm._l((_vm.groups),function(item,i){return _c('router-link',{key:i,staticClass:"menu__item",class:{
              'menu__item--active': _vm.group.id === item.id,
            },attrs:{"to":{
              name: 'howTo',
              params: {
                groupId: item.id,
              },
            },"tag":"div"}},[_vm._v(_vm._s(item.name))])}),1)]):_vm._e(),(_vm.group !== null)?_c('p',[_vm._v(_vm._s(_vm.group.recipe))]):_vm._e()]),(_vm.group !== null)?_c('div',{staticClass:"postPage__line-bottom"},[(_vm.group.recipeUrl)?_c('div',{staticClass:"post__video"},[_c('LazyYoutube',{attrs:{"src":_vm.group.recipeUrl}})],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }